var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.noVod
        ? _c(
            "div",
            [
              _c("VodStatusIndicator", {
                attrs: { status: _vm.matchData.vod_status, variant: "long" }
              }),
              _c("p", [
                _vm._v("Use the form to upload video for the whole match")
              ]),
              _c("SubmitMatchVod", { attrs: { "match-id": _vm.matchId } })
            ],
            1
          )
        : _vm.videoInProgress
        ? _c(
            "div",
            [
              _c("VodStatusIndicator", {
                attrs: { status: _vm.matchData.vod_status, variant: "long" }
              })
            ],
            1
          )
        : !_vm.fetchVideoUrlParams
        ? _c("InfoAlert", [
            _vm._v(" Please, select a round from the timeline above ")
          ])
        : _c("ApiLoadingController", {
            attrs: { fetch: _vm.fetchMapData, params: _vm.fetchMapDataParams },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var mapData = ref.data
                  return [
                    _c("ApiLoadingController", {
                      attrs: {
                        fetch: _vm.fetchVideoUrl,
                        params: _vm.fetchVideoUrlParams
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var videoUrl = ref.data
                              return [
                                _c(
                                  "div",
                                  { staticClass: "content" },
                                  [
                                    _c("VodReplay", {
                                      staticClass: "main",
                                      attrs: {
                                        "current-time": _vm.currentTime,
                                        "playback-rate": _vm.playbackRate,
                                        playing: _vm.playing,
                                        url: videoUrl
                                      },
                                      on: {
                                        "update:currentTime": function($event) {
                                          _vm.currentTime = $event
                                        },
                                        "update:current-time": function(
                                          $event
                                        ) {
                                          _vm.currentTime = $event
                                        },
                                        "update:playbackRate": function(
                                          $event
                                        ) {
                                          _vm.playbackRate = $event
                                        },
                                        "update:playback-rate": function(
                                          $event
                                        ) {
                                          _vm.playbackRate = $event
                                        },
                                        "update:playing": function($event) {
                                          _vm.playing = $event
                                        }
                                      }
                                    }),
                                    _c("Killfeed", {
                                      staticClass: "side",
                                      attrs: {
                                        agents: _vm.agentsById,
                                        events: _vm.eventsForKillfeed(
                                          mapData,
                                          _vm.roundNum
                                        ),
                                        "match-players": mapData.matchPlayers,
                                        players: mapData.players,
                                        "round-teams": mapData.roundTeams,
                                        rounds: mapData.rounds,
                                        weapons: _vm.weaponsById
                                      },
                                      on: {
                                        select: function($event) {
                                          _vm.currentTime =
                                            $event.round_time_millis
                                        }
                                      }
                                    }),
                                    _c("Timeline", {
                                      staticClass: "foot",
                                      attrs: {
                                        "current-time": _vm.currentTime,
                                        data: mapData,
                                        events: _vm.eventsForKillfeed(
                                          mapData,
                                          _vm.roundNum
                                        ),
                                        playable: true,
                                        "playback-rate": _vm.playbackRate,
                                        playing: _vm.playing,
                                        "round-duration": _vm.roundDuration(
                                          mapData,
                                          _vm.roundNum
                                        ),
                                        selected: _vm.selectedForTimeline(
                                          mapData,
                                          _vm.roundNum
                                        ),
                                        selectable: false
                                      },
                                      on: {
                                        "update:currentTime": function($event) {
                                          _vm.currentTime = $event
                                        },
                                        "update:current-time": function(
                                          $event
                                        ) {
                                          _vm.currentTime = $event
                                        },
                                        "update:playbackRate": function(
                                          $event
                                        ) {
                                          _vm.playbackRate = $event
                                        },
                                        "update:playback-rate": function(
                                          $event
                                        ) {
                                          _vm.playbackRate = $event
                                        },
                                        "update:playing": function($event) {
                                          _vm.playing = $event
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          },
                          {
                            key: "no-data",
                            fn: function() {
                              return undefined
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                }
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }